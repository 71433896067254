
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
//@import "variables";

// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/* Variables */
$banner-height: 650px;
$brand-primary: #73308c;
$brand-secondary: #fde67b;

/* Reset */
// * {
//     outline: 0 !important;
// }

/* Style */
body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #333;
}
h1, h2, h3, h4, h5 {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: $brand-primary;
}
// h1:before, h1:after {
//     content: '';
//     position: absolute;
//     width: 65px;
//     border-top: 1px solid $brand-primary;
// }
// h1:before {
//     margin: 12px 0 0 -95px;
// }
// h1:after {
//     margin: 12px 0 0 30px;
// }
// h2 {
//     font-size: 1em;
//     color: $brand-primary;
// }
.lowercase {
    text-transform: none;
}
.btn-more {
    padding: 12px 14px;

    color: #fff;
    background-color: rgb(0, 116, 212);

    border-radius: 0;
    text-decoration: none;
}
.btn-more:link, .btn-more:hover, .btn-more:focus {
    color: #fff;
    background-color: rgb(0, 116, 212);
    text-decoration: none;
}
.btn-more:active {
    background-color: rgb(0, 116, 212);
    transform: scale(.99);
}
.page .btn {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.swal2-container {
    right: 25px !important;
    bottom: 25px !important;

    .swal2-popup.swal2-toast {
        padding: 1em;

        .swal2-title {
            font-size: 2em;
        }
    }
}
.alert-success {
    background-color: #C221F8;
    color: #fff;
    border-color: #ac1ddc;
}

/* Header */
#header {
    #carousel-banner {
        position: relative;

        .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 15;
            
            #logo {
                max-width: 327px;
                display: block;
                margin-top: 30px;

                img {
                    width: 100%;
                }
            }
            .navbar {
                margin-top: 27px;

                .navbar-toggler {
                    border: none;
                    border-radius: 0;
                    box-shadow: none;

                    .navbar-toggler-icon {
                        // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 116, 212)' stroke-linecap='square' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
                        background-image: none;
                    }
                }
                .offcanvas {
                    background-image: linear-gradient(#3d61bc, #273f7b);

                    .offcanvas-header {
                        .btn-close {
                            opacity: 1;
                        }
                    }
                    .offcanvas-body {
                        .navbar-nav {
                            .nav-item {
                                font-size: 22px;
                                text-align: center;
                                border-bottom: 1px solid #273f7b;

                                &:first-child,
                                &:nth-child(2),
                                &:last-child {
                                    border-bottom: none;
                                }
                                a {
                                    font-family: "Roboto", sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    color: #fff;

                                    &:hover,
                                    &.active {
                                        color: #fde67b;
                                    }
                                }
                                &:nth-child(2) {
                                    background-color: red;

                                    a {
                                        font-weight: bold;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            nav#main {
                height: 60px;
    
                display: flex;
                margin-top: 30px;
    
                ul {
                    width: 100%;
    
                    display: flex;
                    justify-content: space-evenly;
    
                    margin: 0;
                    padding: 0;
    
                    list-style-type: none;
    
                    li {
                        a {
                            height: 60px;
                            padding: 16px 20px 0;
                            display: inline-block;
    
                            font-weight: 300;
                            font-size: 18px;
                            text-transform: uppercase;
                            color: #fff;
    
                            text-decoration: none;
                        }
                        a:hover {
                            color: #fde67b;
                        }
                    }
                    li.active {
                        a {
                            color: #fde67b;
                        }
                    }
                    li.free-gifts {
                        padding: 0 50px;
    
                        background-color: rgba(255, 0, 0, 0.7);
    
                        a {
                            padding: 18px 0 0;
                        }
                        a:hover {
                            color: #fff;
                            border-bottom: 2px solid;
                        }
                    }
                    li.free-gifts.active {
                        a {
                            color: #fff;
                            border-bottom: 2px solid;
                        }
                    }
                }
                ul:first-child {
                    background: url('/img/bg-nav-main.png') repeat-x;
                }
            }
        }
        .carousel-inner {
            .carousel-item {
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.3);
                }
                img {
                    object-fit: cover;
                    min-height: 650px;
                    width: 100%;
                }
                .carousel-caption {
                    top: 45%;

                    .quote {
                        text-align: center;
        
                        h5 {
                            font-size: 1.4em;
                            color: #fff;
                        }
                        .author {
                            margin-top: 40px;
        
                            font-size: 1.1em;
                            color: #fde67b;
                            text-shadow: 1px 1px #1f1a1a;
        
                            .highlight {
                                margin-top: 15px;
        
                                font-style: italic;
                                font-size: 0.8em;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    // .carousel-control {
    //     opacity: 1;

    //     .icon-prev {
    //         width: 53px;
    //         height: 103px;

    //         margin-top: -51px;

    //         background: url('/img/bg-arrow-left.png') no-repeat;
    //     }
    //     .icon-prev:before {
    //         content: '';
    //     }
    //     .icon-next {
    //         width: 53px;
    //         height: 103px;

    //         margin-top: -51px;

    //         background: url('/img/bg-arrow-right.png') no-repeat;
    //     }
    //     .icon-next:before {
    //         content: '';
    //     }
    // }
    @media (max-width: 1199.98px) {
        #carousel-banner {
            .wrapper {
                nav#main {
                    ul {
                        li.free-gifts {
                            padding: 0 25px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 991.98px) {
        #carousel-banner {
            .wrapper {
                nav#main {
                    ul {
                        li {
                            a {
                                padding: 8px 12px 0;

                                font-size: 16px;
                                text-align: center;
                            }
                        }
                        li.free-gifts {
                            padding: 0 15px;

                            a {
                                padding: 8px 12px 0;
                            }
                        }
                        li:nth-child(3),
                        li:nth-child(4) {
                            a {
                                padding: 18px 12px 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 767.98px) {
        #carousel-banner {
            .wrapper {
                #logo {
                    max-width: 275px;
                }
                nav#main {
                    display: none;
                }
            }
            .carousel-inner {
                .carousel-item {
                    .carousel-caption {
                        top: 35%;
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        #carousel-banner {
            .wrapper {
                .navbar {
                    display: none;
                }
            }
        }
    }
}

/* Content - Pages */
.page {
    #jim-and-jim {
        .jim-britt, .jim-lutes {
            font-size: 14px;
            line-height: 26px;
        }
        .jim-lutes {
            margin-top: 50px;
        }
    }
    .mindset-makeover {
        .banner {
            height: 50px;
            position: fixed;
            z-index: 100;
            padding: 12px 20px;

            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            -webkit-transform-origin: 0 50%;
               -moz-transform-origin: 0 50%;
                -ms-transform-origin: 0 50%;
                 -o-transform-origin: 0 50%;
                    transform-origin: 0 50%;
            -webkit-transform: rotate(-90deg) translate(-50%, 50%);
               -moz-transform: rotate(-90deg) translate(-50%, 50%);
                -ms-transform: rotate(-90deg) translate(-50%, 50%);
                 -o-transform: rotate(-90deg) translate(-50%, 50%);
                    transform: rotate(-90deg) translate(-50%, 50%);

            font-size: 16px;
            color: #000;
            background-color: #fde67b;
            text-align: center;
            
            .highlight {
                font-size: 18px;
                font-weight: 500;
                color: rgb(200, 0, 0);
            }
        }
        #mindset-makeover {
            text-align: center;

            h3 {
                font-size: 22px;
            }
            p {
                margin-top: 25px;

                font-size: 16px;
            }
            .learn-more {
                display: inline-block;
                padding: 9px 11px;

                font-size: 14px;
                font-weight: 500;

                color: #fff;
                text-transform: uppercase;
                background-color: #18C3CA;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

/* Content - Home Page */
.page-home {
    #content {
        #home {
            padding: 100px 0;

            h2 {
                margin-bottom: 50px;
                text-align: center;
            }
            .profile-pic {
                text-align: center;

                img {
                    width: 100%;
                    max-width: 232px;
                }
            }
            .hi-sarah-aguinaldo {
                margin-top: 15px;
                
                text-align: center;

                a {
                    font-weight: bold;
                    color: $brand-primary;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                    span {
                        color: red;
                    }
                }
            }
            .bio {
                p {
                    text-indent: 40px;

                    a {
                        color: #337ab7;
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .btn-more {
                    margin-top: 25px;
                }
            }
        }
        @media (max-width: 767.98px) {
            #home {
                .bio {
                    margin-top: 25px;
                }
            }
            // .mindset-makeover {
            //     display: none;
            // }
        }
    }
}

/* Content - Sarah Aguinaldo Page */
.page-sarah-aguinaldo {
    #content {
        .mindset-makeover {
            display: none;
        }
        #sarah-aguinaldo {
            padding: 100px 0;

            h2 {
                text-align: center;
            }
            .bio {
                margin-top: 50px;

                p {
                    text-indent: 40px;

                    a {
                        color: #337ab7;
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .profile-pic {
                    margin: 25px 0;
                    
                    text-align: center;

                    img {
                        max-width: 232px;
                    }
                }
            }
        }
    }
}

/* Content - Sarah Aguinaldo Message Page */
.page-sarah-aguinaldo-message {
    #content {
        .mindset-makeover {
            display: none;
        }
        #sarah-aguinaldo-message {
            padding: 100px 0;
            
            h2 {
                text-align: center;
            }
            .message {
                margin-top: 50px;
    
                .intro {
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                }
                .highlight {
                    font-weight: bold;
                }
                .highlight-2 {
                    color: #C221F8;
                }
            }
        }
    }
}

/* Content - Articles Page */
.page-articles {
    #content {
        #articles {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .no-1-best-seller {
                margin-top: 50px;

                text-align: center;

                img {
                    max-width: 175px;
                }
            }
            .falling-in-love-with-yourself {
                margin-top: 50px;
    
                .cover {
                    max-width: 310px;
    
                    box-shadow: 10px 10px 5px #ccc;

                    img {
                        width: 100%;
                    }
                }
                .description {
                    p {
                        a {
                            color: #337ab7;
                            text-decoration: none;
            
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 991.98px) {
            #articles {
                .cover {
                    margin: 0 auto 50px;
                }
            }
        }
    }
}

/* Content - Articles - Creating Happiness Page */
.page-articles-creating-happiness {
    #content {
        #creating-happiness {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .creating-happiness {
                margin-top: 50px;
    
                .cover {
                    max-width: 310px;
    
                    box-shadow: 10px 10px 5px #ccc;

                    img {
                        width: 100%;
                    }
                }
                .description {
                    p {
                        a {
                            color: #337ab7;
                            text-decoration: none;
            
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 991.98px) {
            #creating-happiness {
                .cover {
                    margin: 0 auto 50px;
                }
            }
        }
    }
}

/* Content - Articles - I Am Success Page */
.page-articles-i-am-success {
    #content {
        #i-am-success {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .i-am-success {
                margin-top: 50px;
    
                .cover {
                    max-width: 310px;
    
                    box-shadow: 10px 10px 5px #ccc;

                    img {
                        width: 100%;
                    }
                }
                .description {
                    p {
                        a {
                            color: #337ab7;
                            text-decoration: none;
            
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 991.98px) {
            #i-am-success {
                .cover {
                    margin: 0 auto 50px;
                }
            }
        }
    }
}

/* Content - Articles - Why Did You Do This To Me Page */
.page-articles-why-did-you-do-this-to-me {
    #content {
        #why-did-you-do-this-to-me {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .why-did-you-do-this-to-me {
                margin-top: 50px;
    
                .cover {
                    max-width: 310px;
    
                    box-shadow: 10px 10px 5px #ccc;

                    img {
                        width: 100%;
                    }
                }
                .description {
                    p {
                        a {
                            color: #337ab7;
                            text-decoration: none;
            
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 991.98px) {
            #why-did-you-do-this-to-me {
                .cover {
                    margin: 0 auto 50px;
                }
            }
        }
    }
}

/* Content - Articles - Falling In Love With Yourself Page */
.page-articles-falling-in-love-with-yourself {
    #falling-in-love-with-yourself {
        padding: 100px 0;

        text-align: center;

        .falling-in-love-with-yourself {
            margin-top: 50px;

            .cover {
                width: 310px;
                height: 401px;

                float: left;

                background: url('/img/articles/bg-falling-in-love-with-yourself.jpg?v=2') no-repeat;

                box-shadow: 10px 10px 5px #ccc;
            }
            .description {
                width: 570px;

                float: right;

                font-family: 'Georgia', Arial, sans-serif;
                font-size: 1.3em;
                text-align: left;
                line-height: 35px;
            }
            .add-to-cart {
                float: left;
                padding: 10px 13px;

                font-family: 'Gill Sans', Arial, sans-serif;
                font-size: 1em;
                color: #000;
                line-height: 15px;

                text-decoration: none;
                text-align: center;

                border: 1px solid #000;
                background-color: #e9c881;
            }
        }
    }
}

/* Content - Articles - Women Cheat Page */
.page-articles-women-cheat {
    #content {
        #women-cheat {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .women-cheat {
                margin-top: 50px;
    
                .cover {
                    max-width: 310px;
    
                    box-shadow: 10px 10px 5px #ccc;

                    img {
                        width: 100%;
                    }
                }
                .description {
                    p {
                        a {
                            color: #337ab7;
                            text-decoration: none;
            
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 991.98px) {
            #women-cheat {
                .cover {
                    margin: 0 auto 50px;
                }
            }
        }
    }
}

/* Content - Articles - Consciousness Is The Catalyst For Change */
.page-articles-consciousness-is-the-catalyst-for-change {
    #content {
        #consciousness-is-the-catalyst-for-change {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .consciousness-is-the-catalyst-for-change {
                margin-top: 50px;
    
                .cover {
                    max-width: 310px;
    
                    box-shadow: 10px 10px 5px #ccc;

                    img {
                        width: 100%;
                    }
                }
                .description {
                    p {
                        a {
                            color: #337ab7;
                            text-decoration: none;
            
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 991.98px) {
            #consciousness-is-the-catalyst-for-change {
                .cover {
                    margin: 0 auto 50px;
                }
            }
        }
    }
}

/* Content - Books */
.page-books {
    #content {
        #books {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .books {
                margin-top: 50px;
    
                .cover {
                    max-width: 310px;

                    img {
                        width: 100%;
                    }
                }
                .description {
                    p {
                        a {
                            color: #337ab7;
                            text-decoration: none;
            
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        .highlight {
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
        @media (max-width: 991.98px) {
            #books {
                .cover {
                    margin: 0 auto 50px;
                }
            }
        }
    }
}

/* Content - Videos Page */
.page-videos {
    #videos {
        padding: 100px 0;

        text-align: center;

        .videos {
            margin-top: 50px;

            font-family: 'Georgia', Arial, sans-serif;
            font-size: 1.3em;
            text-align: left;

            .video {
                text-align: center;
            }
            .add-to-cart {
                width: 150px;

                display: block;
                margin: 0 auto;
                padding: 10px 13px;

                font-family: 'Gill Sans', Arial, sans-serif;
                font-size: 1em;
                color: #000;
                line-height: 15px;

                text-decoration: none;
                text-align: center;

                border: 1px solid #000;
                background-color: #e9c881;
            }
        }
    }
}

/* Content - Free Gifts */
.page-free-gifts {
    #content {
        #free-gifts {
            padding: 100px 0;
    
            h2, h3 {
                text-align: center;
            }
            .tagline {
                margin: 15px 0 0;
    
                font-size: 20px;
                color: #000;
                text-align: center;
            }
            .articles {
                margin-top: 50px;
                display: flex;
                justify-content: space-around;
    
                article {
                    text-align: center;

                    .cover {
                        max-width: 310px;
                        margin-bottom: 25px;
    
                        display: block;
    
                        border: 1px solid #000;

                        img {
                            width: 100%;
                        }
                    }
                    .more-info {
                        font-size: 1.2em;
                        color: $brand-primary;
    
                        text-transform: uppercase;
                        text-decoration: none;
                    }
                }
            }
            .process-free-gifts {
                margin-top: 100px;
    
                .alert {
                    border-radius: 0;

                    ul {
                        margin: 0;
                    }
                }
                .form-free-gifts {
                    margin: 50px auto 0;
                    max-width: 500px;
    
                    label {
                        font-weight: 500;
                    }
                    input[type="text"],
                    input[type="email"],
                    input[type="tel"] {
                        height: auto;
    
                        padding: 12px 16px;
    
                        font-size: 16px;
                        border-radius: 0;
                    }
                    .btn-submit {
                        color: #fff;
                        background-color: #C221F8;
                        border-color: #ac1ddc;
                        border-radius: 0;
                    }
                    a {
                        color: #337ab7;
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        @media (max-width: 767.98px) {
            #free-gifts {
                .articles {
                    display: block;
                    max-width: 310px;
                    margin: 50px auto 0;

                    article:last-child {
                        margin-top: 50px;
                    }
                }
            }
        }
    }
}

/* Content - Healing Herbs Page */
.page-healing-herbs {
    #content {
        #healing-herbs {
            padding: 100px 0;
    
            text-align: center;
    
            .healing-herbs {
                margin-top: 50px;
    
                #carousel-healing-herbs {
                    padding: 0 150px 100px;
    
                    .carousel-inner {
                        .carousel-item {
                            .product {
                                .product-img {
                                    img {
                                        width: 100%;
    
                                        border: 2px solid #73308c;
                                    }
                                    .caption {
                                        margin-top: 5px;
    
                                        font-size: 14px;
                                    }
                                }
                                .product-description {
                                    p {
                                        margin-bottom: 25px;
    
                                        text-align: left;

                                        a {
                                            color: #337ab7;
                                            text-decoration: none;
                            
                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .carousel-indicators {
                        button {
                            background-color: $brand-primary;
                        }
                    }
                }
                .disclaimer {
                    margin-top: 50px;
                }
            }
        }
        @media (max-width: 991.98px) {
            #healing-herbs {
                .healing-herbs {
                    #carousel-healing-herbs {
                        .carousel-inner {
                            .carousel-item {
                                .product {
                                    .product-description {
                                        margin-top: 50px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 767.98px) {
            #healing-herbs {
                .healing-herbs {
                    #carousel-healing-herbs {
                        padding: 0 0 100px;

                        .carousel-control-prev,
                        .carousel-control-next {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/* Content - 1:1 Coaching Page */
.page-1-1-coaching {
    #content {
        .mindset-makeover {
            display: none;
        }
        #one-on-one-coaching {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .features {
                margin-top: 50px;
    
                .intense-private-coaching {
                    text-align: center;
                }
                .includes {
                    margin-top: 50px;
    
                    ul {
                        list-style: none;
    
                        li {
                            margin-top: 10px;
    
                            span:has(span) {
                                position: relative;
                                left: 10px;
    
                                .highlight {
                                    font-weight: bold;
                                    color: red;
                                }
                            }
                        }
                    }
                    ul li:before {
                        content: '✓';
                        font-size: 28px;
                        color: rgb(0, 191, 0);
                    }
                }
                .form {
                    margin-top: 50px;
    
                    p {
                        margin-bottom: 0;

                        text-align: center;
    
                        &:first-child {
                            color: #337ab7;
                        }
                        .highlight {
                            font-style: normal;
                        }
                    }
                    > ul {
                        color: #337ab7;
                        text-align: left;
                    }
                    .form-1-1-coaching {
                        max-width: 500px;
                        margin: 50px auto 0;
    
                        text-align: left;
    
                        .alert {
                            border-radius: 0;
        
                            ul {
                                margin: 0;
                            }
                        }
                        ul {
                            margin-left: 25px;
    
                            font-size: 16px;
                        }
                        label {
                            font-weight: 500;
                        }
                        input[type="text"],
                        input[type="email"],
                        input[type="tel"] {
                            height: auto;
    
                            padding: 12px 16px;
    
                            font-size: 16px;
                            border-radius: 0;
                        }
                        .btn-submit {
                            color: #fff;
                            background-color: #C221F8;
                            border-color: #ac1ddc;
                            border-radius: 0;
                        }
                        a {
                            color: #337ab7;
                            text-decoration: none;
            
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Content - Special Offers Page */
.page-special-offers {
    #special-offers {
        padding: 100px 0;

        text-align: center;

        .special-offers {
            margin-top: 50px;

            font-family: 'Georgia', Arial, sans-serif;
            font-size: 1.3em;

            .special-offers-articles {
                width: 685px;
                height: 322px;

                margin: 0 auto;

                background: url('/img/bg-special-offers-articles.png?v=2') no-repeat;
            }
            .highlight {
                font-size: 3.5em;
                color: #ccc;
            }
            .special-offers-best-seller {
                width: 310px;
                height: 401px;

                display: block;

                margin: 0 auto;
            }
            .discount {
                color: #ff0000;

                span {
                    font-size: 1.5em;
                }
            }
            .line-through {
                font-size: 1.3em;
                text-decoration: line-through;
            }
            .add-to-cart {
                width: 175px;

                display: block;
                margin: 0 auto;
                padding: 10px 13px;

                font-family: 'Gill Sans', Arial, sans-serif;
                font-size: 1em;
                color: #000;
                line-height: 15px;

                border: 1px solid #000;
                background-color: #e9c881;
            }
        }
    }
}

/* Content - Cart Page */
.page-cart {
    #cart {
        padding: 100px 0;

        text-align: center;

        .cart {
            margin-top: 50px;

            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 1.3em;
            text-align: left;
        }
    }
}

/* Content - Cart Login Page */
.page-cart-login {
    #cart-login {
        padding: 100px 0;

        text-align: center;

        .cart-login {
            margin-top: 50px;

            font-family: 'Georgia', Arial, sans-serif;
            font-size: 1.3em;
            text-align: left;

            .sign-up {
                padding-left: 0;

                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
            .login {
                padding-right: 0;

                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
            label {
                font-size: 0.8em;
            }
            .alert {
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 0.8em;
            }
        }
    }
}

/* Content - Pay Page */
.page-pay {
    #pay {
        padding: 100px 0;

        text-align: center;

        .pay {
            margin-top: 50px;

            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 1.3em;
            text-align: left;

            .alert {
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 0.8em;
            }
            .payment {
                margin-top: 50px;

                form {
                    width: 500px;

                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                    font-size: 0.8em;
                }
                .row {
                    .col-xs-6:first-child {
                        padding-left: 0;
                    }
                    .col-xs-6:nth-child(2) {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

/* Content - Pay Success Page */
.page-checkout-success {
    #checkout-success {
        padding: 100px 0;

        text-align: center;

        .checkout-success {
            margin-top: 50px;

            font-family: 'Georgia', Arial, sans-serif;
            font-size: 1.3em;
        }
    }
}

/* Content - Contact Page */
.page-contact {
    #content {
        #contact {
            padding: 100px 0;
    
            text-align: center;
    
            .contact {
                margin-top: 50px;

                p {
                    &:last-child {
                        margin-top: 25px;
                    }
                    a {
                        color: #337ab7;
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

/* Content - Dedications Page */
.page-dedications {
    #content {
        #dedications {
            padding: 100px 0;
    
            text-align: center;
    
            .dedications {
                margin-top: 50px;
    
                p {
                    margin-top: 50px;

                    &:first-child {
                        margin-top: 0;
    
                        font-weight: bold;
                    }
                }
                .highlight {
                    font-weight: bold;
                    color: $brand-primary;
                }
                .dedication {
                    max-width: 500px;
                    margin: 50px auto 0;
                    padding: 15px;
    
                    background-color: #f6f6f6;
                    box-shadow: 7px 7px 0 $brand-primary;
                }
            }
        }
    }
}

/* Content - Privacy Policy Page */
.page-privacy {
    #content {
        #privacy-policy {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .privacy-policy {
                margin-top: 50px;
    
                ul {
                    margin: 25px 0 0 25px;
                }
            }
        }
    }
}

/* Content - Terms & Conditions Page */
.page-terms {
    #content {
        #terms-and-conditions {
            padding: 100px 0;
    
            h2 {
                text-align: center;
            }
            .terms-and-conditions {
                margin-top: 50px;
    
                ul {
                    margin: 25px 0 0 25px;
                }
            }
        }
    }
}

/* Footer */
#footer {
    padding: 50px 0;

    background-color: #35363a;

    .social-network {
        ul {
            padding: 0;

            li {
                margin-left: 10px;
                display: inline-block;

                &:first-child {
                    margin-left: 0;
                }
                a {
                    max-width: 30px;
                    display: block;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .copyright {
        text-align: center;
        color: #898989;
    }
    .designed_hosted_by {
        margin-bottom: 0;

        color: #898989;
        text-align: center;

        a {
            color: #898989;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
    }
    nav {
        text-align: right;

        ul {
            margin: 0;
            padding: 0;

            list-style-type: none;

            li {
                display: inline;
                margin-right: 2px;
                padding-left: 10px;

                background: url('/img/bg-bullet.png') no-repeat center left;

                a {
                    color: #c1c0c0;

                    text-decoration: none;
                }
                a:hover {
                    text-decoration: underline;
                }
            }
            li:first-child {
                padding-left: 0;

                background: none;
            }
        }
    }
    @media (max-width: 991.98px) {
        .social-network {
            text-align: center;
        }
        .copyright {
            margin-top: 25px;
        }
        nav {
            margin-top: 25px;
            
            text-align: center;
        }
    }
}

/* Client Area - My Account */
.page-client-area-my-account {
    #my-account {
        padding: 100px 0;

        text-align: center;

        .my-account {
            margin-top: 50px;

            font-size: 1.2em;
            text-align: left;

            .my-profile, .edit-profile, .order-history, .view-order, .view-downloads, .invoices, .view-invoice {
                margin-top: 25px;
            }
            .edit-profile {
                font-size: 14px;

                form {
                    width: 460px;
                }
            }
        }
    }
}

/* Client Area - Show Invoice */
.page-client-area-show-invoice {
    padding: 75px 0 100px;

    #billing-info {
        margin-top: 50px;

        .col-xs-6:first-child {
            padding-left: 0;
        }
        .col-xs-6:last-child {
            padding-right: 0;
        }
    }
    @media print {
        .panel {
            .panel-heading {
                background-color: #337ab7 !important;

                h3 {
                    color: #fff !important;
                }
            }
        }
        table {
            thead {
                th {
                    color: #fff !important;

                    background: #337ab7 !important;
                    -webkit-print-color-adjust: exact;
                }
            }
        }
        .btn-print {
            display: none;
        }
    }
}

/* Client Area - Login */
.page-client-area-login {
    #client-area-login {
        padding: 100px 0;

        text-align: center;

        .client-area-login {
            margin-top: 50px;

            font-family: 'Georgia', Arial, sans-serif;
            font-size: 1.3em;

            .sign-in, .register {
                text-align: left;

                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                
                a {
                    font-size: 0.8em;
                }
            }
            .sign-in {
                padding-left: 0;
            }
            .register {
                padding-right: 0;
            }

            label {
                font-size: 0.8em;
            }
            .alert {
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 0.8em;
                text-align: left;
            }
        }
    }
}

/* Client Area - Forgot Password? */
.page-client-area-forgot-password {
    #client-area-forgot-password {
        padding: 100px 0;

        text-align: center;

        .client-area-forgot-password {
            margin-top: 50px;

            font-family: 'Georgia', Arial, sans-serif;
            font-size: 1.3em;

            .forgot-password {
                float: none;
                margin: 0 auto;

                text-align: left;

                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

                a {
                    font-size: 0.8em;
                }
            }

            label {
                font-size: 0.8em;
            }
            .alert {
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 0.8em;
                text-align: left;
            }
        }
    }
}

/* Admin Area - General */
[class*="page-admin-area"] {
    padding: 75px 0 100px;
}

/* Admin Area - Login */
.page-admin-area-login {
    padding: 100px 0;

    .login {
        width: 350px;

        margin: 0 auto;

        #logo {
            width: 327px;
            height: 58px;

            display: block;
            margin: 0 auto;

            background: url('/img/logo.png') no-repeat;
        }
        .panel {
            margin-top: 50px;
        }
    }
}

/* Admin Area - Dashboard */
.page-admin-area-dashboard {
    .dashboard {
        .statistics {
            padding: 0;

            .col-xs-4:first-child {
                padding-left: 0;
            }
            .col-xs-4:last-child {
                padding-right: 0;
            }
        }
    }
}

/* Admin Area - Show Invoice */
.page-admin-area-view-invoice {
    .panel {
        border-radius: 0;

        .panel-heading {
            border-radius: 0;
        }
    }
    #billing-info {
        margin-top: 50px;

        .col-xs-6:first-child {
            padding-left: 0;
        }
        .col-xs-6:last-child {
            padding-right: 0;
        }
    }
    @media print {
        .panel {
            .panel-heading {
                background-color: #337ab7 !important;

                h3 {
                    color: #fff !important;
                }
            }
        }
        table {
            thead {
                th {
                    color: #fff !important;

                    background: #337ab7 !important;
                    -webkit-print-color-adjust: exact;
                }
            }
        }
        .btn-print {
            display: none;
        }
    }
}

/* Admin Area - Footer */
.footer-admin {
    .container {
        padding-top: 13px;
    }
}
