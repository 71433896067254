@charset "UTF-8";
/* Variables */
/* Reset */
/* Style */
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: #333;
}

h1, h2, h3, h4, h5 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #73308c;
}

.lowercase {
  text-transform: none;
}

.btn-more {
  padding: 12px 14px;
  color: #fff;
  background-color: #0074d4;
  border-radius: 0;
  text-decoration: none;
}

.btn-more:link, .btn-more:hover, .btn-more:focus {
  color: #fff;
  background-color: #0074d4;
  text-decoration: none;
}

.btn-more:active {
  background-color: #0074d4;
  transform: scale(0.99);
}

.page .btn {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.swal2-container {
  right: 25px !important;
  bottom: 25px !important;
}

.swal2-container .swal2-popup.swal2-toast {
  padding: 1em;
}

.swal2-container .swal2-popup.swal2-toast .swal2-title {
  font-size: 2em;
}

.alert-success {
  background-color: #C221F8;
  color: #fff;
  border-color: #ac1ddc;
}

/* Header */
#header #carousel-banner {
  position: relative;
}

#header #carousel-banner .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
}

#header #carousel-banner .wrapper #logo {
  max-width: 327px;
  display: block;
  margin-top: 30px;
}

#header #carousel-banner .wrapper #logo img {
  width: 100%;
}

#header #carousel-banner .wrapper .navbar {
  margin-top: 27px;
}

#header #carousel-banner .wrapper .navbar .navbar-toggler {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

#header #carousel-banner .wrapper .navbar .navbar-toggler .navbar-toggler-icon {
  background-image: none;
}

#header #carousel-banner .wrapper .navbar .offcanvas {
  background-image: linear-gradient(#3d61bc, #273f7b);
}

#header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-header .btn-close {
  opacity: 1;
}

#header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-body .navbar-nav .nav-item {
  font-size: 22px;
  text-align: center;
  border-bottom: 1px solid #273f7b;
}

#header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-body .navbar-nav .nav-item:first-child, #header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-body .navbar-nav .nav-item:nth-child(2), #header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-body .navbar-nav .nav-item:last-child {
  border-bottom: none;
}

#header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-body .navbar-nav .nav-item a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
}

#header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-body .navbar-nav .nav-item a:hover, #header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-body .navbar-nav .nav-item a.active {
  color: #fde67b;
}

#header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-body .navbar-nav .nav-item:nth-child(2) {
  background-color: red;
}

#header #carousel-banner .wrapper .navbar .offcanvas .offcanvas-body .navbar-nav .nav-item:nth-child(2) a {
  font-weight: bold;
  color: #fff;
}

#header #carousel-banner .wrapper nav#main {
  height: 60px;
  display: flex;
  margin-top: 30px;
}

#header #carousel-banner .wrapper nav#main ul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#header #carousel-banner .wrapper nav#main ul li a {
  height: 60px;
  padding: 16px 20px 0;
  display: inline-block;
  font-weight: 300;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}

#header #carousel-banner .wrapper nav#main ul li a:hover {
  color: #fde67b;
}

#header #carousel-banner .wrapper nav#main ul li.active a {
  color: #fde67b;
}

#header #carousel-banner .wrapper nav#main ul li.free-gifts {
  padding: 0 50px;
  background-color: rgba(255, 0, 0, 0.7);
}

#header #carousel-banner .wrapper nav#main ul li.free-gifts a {
  padding: 18px 0 0;
}

#header #carousel-banner .wrapper nav#main ul li.free-gifts a:hover {
  color: #fff;
  border-bottom: 2px solid;
}

#header #carousel-banner .wrapper nav#main ul li.free-gifts.active a {
  color: #fff;
  border-bottom: 2px solid;
}

#header #carousel-banner .wrapper nav#main ul:first-child {
  background: url("/img/bg-nav-main.png") repeat-x;
}

#header #carousel-banner .carousel-inner .carousel-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

#header #carousel-banner .carousel-inner .carousel-item img {
  object-fit: cover;
  min-height: 650px;
  width: 100%;
}

#header #carousel-banner .carousel-inner .carousel-item .carousel-caption {
  top: 45%;
}

#header #carousel-banner .carousel-inner .carousel-item .carousel-caption .quote {
  text-align: center;
}

#header #carousel-banner .carousel-inner .carousel-item .carousel-caption .quote h5 {
  font-size: 1.4em;
  color: #fff;
}

#header #carousel-banner .carousel-inner .carousel-item .carousel-caption .quote .author {
  margin-top: 40px;
  font-size: 1.1em;
  color: #fde67b;
  text-shadow: 1px 1px #1f1a1a;
}

#header #carousel-banner .carousel-inner .carousel-item .carousel-caption .quote .author .highlight {
  margin-top: 15px;
  font-style: italic;
  font-size: 0.8em;
  color: #fff;
}

@media (max-width: 1199.98px) {
  #header #carousel-banner .wrapper nav#main ul li.free-gifts {
    padding: 0 25px;
  }
}

@media (max-width: 991.98px) {
  #header #carousel-banner .wrapper nav#main ul li a {
    padding: 8px 12px 0;
    font-size: 16px;
    text-align: center;
  }
  #header #carousel-banner .wrapper nav#main ul li.free-gifts {
    padding: 0 15px;
  }
  #header #carousel-banner .wrapper nav#main ul li.free-gifts a {
    padding: 8px 12px 0;
  }
  #header #carousel-banner .wrapper nav#main ul li:nth-child(3) a,
  #header #carousel-banner .wrapper nav#main ul li:nth-child(4) a {
    padding: 18px 12px 0;
  }
}

@media (max-width: 767.98px) {
  #header #carousel-banner .wrapper #logo {
    max-width: 275px;
  }
  #header #carousel-banner .wrapper nav#main {
    display: none;
  }
  #header #carousel-banner .carousel-inner .carousel-item .carousel-caption {
    top: 35%;
  }
}

@media (min-width: 768px) {
  #header #carousel-banner .wrapper .navbar {
    display: none;
  }
}

/* Content - Pages */
.page #jim-and-jim .jim-britt, .page #jim-and-jim .jim-lutes {
  font-size: 14px;
  line-height: 26px;
}

.page #jim-and-jim .jim-lutes {
  margin-top: 50px;
}

.page .mindset-makeover .banner {
  height: 50px;
  position: fixed;
  z-index: 100;
  padding: 12px 20px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transform: rotate(-90deg) translate(-50%, 50%);
  -moz-transform: rotate(-90deg) translate(-50%, 50%);
  -ms-transform: rotate(-90deg) translate(-50%, 50%);
  -o-transform: rotate(-90deg) translate(-50%, 50%);
  transform: rotate(-90deg) translate(-50%, 50%);
  font-size: 16px;
  color: #000;
  background-color: #fde67b;
  text-align: center;
}

.page .mindset-makeover .banner .highlight {
  font-size: 18px;
  font-weight: 500;
  color: #c80000;
}

.page .mindset-makeover #mindset-makeover {
  text-align: center;
}

.page .mindset-makeover #mindset-makeover h3 {
  font-size: 22px;
}

.page .mindset-makeover #mindset-makeover p {
  margin-top: 25px;
  font-size: 16px;
}

.page .mindset-makeover #mindset-makeover .learn-more {
  display: inline-block;
  padding: 9px 11px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  background-color: #18C3CA;
}

.page .mindset-makeover #mindset-makeover .learn-more:hover, .page .mindset-makeover #mindset-makeover .learn-more:focus {
  text-decoration: none;
}

/* Content - Home Page */
.page-home #content #home {
  padding: 100px 0;
}

.page-home #content #home h2 {
  margin-bottom: 50px;
  text-align: center;
}

.page-home #content #home .profile-pic {
  text-align: center;
}

.page-home #content #home .profile-pic img {
  width: 100%;
  max-width: 232px;
}

.page-home #content #home .hi-sarah-aguinaldo {
  margin-top: 15px;
  text-align: center;
}

.page-home #content #home .hi-sarah-aguinaldo a {
  font-weight: bold;
  color: #73308c;
  text-decoration: none;
}

.page-home #content #home .hi-sarah-aguinaldo a:hover {
  text-decoration: underline;
}

.page-home #content #home .hi-sarah-aguinaldo a span {
  color: red;
}

.page-home #content #home .bio p {
  text-indent: 40px;
}

.page-home #content #home .bio p a {
  color: #337ab7;
  text-decoration: none;
}

.page-home #content #home .bio p a:hover {
  text-decoration: underline;
}

.page-home #content #home .bio .btn-more {
  margin-top: 25px;
}

@media (max-width: 767.98px) {
  .page-home #content #home .bio {
    margin-top: 25px;
  }
}

/* Content - Sarah Aguinaldo Page */
.page-sarah-aguinaldo #content .mindset-makeover {
  display: none;
}

.page-sarah-aguinaldo #content #sarah-aguinaldo {
  padding: 100px 0;
}

.page-sarah-aguinaldo #content #sarah-aguinaldo h2 {
  text-align: center;
}

.page-sarah-aguinaldo #content #sarah-aguinaldo .bio {
  margin-top: 50px;
}

.page-sarah-aguinaldo #content #sarah-aguinaldo .bio p {
  text-indent: 40px;
}

.page-sarah-aguinaldo #content #sarah-aguinaldo .bio p a {
  color: #337ab7;
  text-decoration: none;
}

.page-sarah-aguinaldo #content #sarah-aguinaldo .bio p a:hover {
  text-decoration: underline;
}

.page-sarah-aguinaldo #content #sarah-aguinaldo .bio .profile-pic {
  margin: 25px 0;
  text-align: center;
}

.page-sarah-aguinaldo #content #sarah-aguinaldo .bio .profile-pic img {
  max-width: 232px;
}

/* Content - Sarah Aguinaldo Message Page */
.page-sarah-aguinaldo-message #content .mindset-makeover {
  display: none;
}

.page-sarah-aguinaldo-message #content #sarah-aguinaldo-message {
  padding: 100px 0;
}

.page-sarah-aguinaldo-message #content #sarah-aguinaldo-message h2 {
  text-align: center;
}

.page-sarah-aguinaldo-message #content #sarah-aguinaldo-message .message {
  margin-top: 50px;
}

.page-sarah-aguinaldo-message #content #sarah-aguinaldo-message .message .intro {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.page-sarah-aguinaldo-message #content #sarah-aguinaldo-message .message .highlight {
  font-weight: bold;
}

.page-sarah-aguinaldo-message #content #sarah-aguinaldo-message .message .highlight-2 {
  color: #C221F8;
}

/* Content - Articles Page */
.page-articles #content #articles {
  padding: 100px 0;
}

.page-articles #content #articles h2 {
  text-align: center;
}

.page-articles #content #articles .no-1-best-seller {
  margin-top: 50px;
  text-align: center;
}

.page-articles #content #articles .no-1-best-seller img {
  max-width: 175px;
}

.page-articles #content #articles .falling-in-love-with-yourself {
  margin-top: 50px;
}

.page-articles #content #articles .falling-in-love-with-yourself .cover {
  max-width: 310px;
  box-shadow: 10px 10px 5px #ccc;
}

.page-articles #content #articles .falling-in-love-with-yourself .cover img {
  width: 100%;
}

.page-articles #content #articles .falling-in-love-with-yourself .description p a {
  color: #337ab7;
  text-decoration: none;
}

.page-articles #content #articles .falling-in-love-with-yourself .description p a:hover {
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  .page-articles #content #articles .cover {
    margin: 0 auto 50px;
  }
}

/* Content - Articles - Creating Happiness Page */
.page-articles-creating-happiness #content #creating-happiness {
  padding: 100px 0;
}

.page-articles-creating-happiness #content #creating-happiness h2 {
  text-align: center;
}

.page-articles-creating-happiness #content #creating-happiness .creating-happiness {
  margin-top: 50px;
}

.page-articles-creating-happiness #content #creating-happiness .creating-happiness .cover {
  max-width: 310px;
  box-shadow: 10px 10px 5px #ccc;
}

.page-articles-creating-happiness #content #creating-happiness .creating-happiness .cover img {
  width: 100%;
}

.page-articles-creating-happiness #content #creating-happiness .creating-happiness .description p a {
  color: #337ab7;
  text-decoration: none;
}

.page-articles-creating-happiness #content #creating-happiness .creating-happiness .description p a:hover {
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  .page-articles-creating-happiness #content #creating-happiness .cover {
    margin: 0 auto 50px;
  }
}

/* Content - Articles - I Am Success Page */
.page-articles-i-am-success #content #i-am-success {
  padding: 100px 0;
}

.page-articles-i-am-success #content #i-am-success h2 {
  text-align: center;
}

.page-articles-i-am-success #content #i-am-success .i-am-success {
  margin-top: 50px;
}

.page-articles-i-am-success #content #i-am-success .i-am-success .cover {
  max-width: 310px;
  box-shadow: 10px 10px 5px #ccc;
}

.page-articles-i-am-success #content #i-am-success .i-am-success .cover img {
  width: 100%;
}

.page-articles-i-am-success #content #i-am-success .i-am-success .description p a {
  color: #337ab7;
  text-decoration: none;
}

.page-articles-i-am-success #content #i-am-success .i-am-success .description p a:hover {
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  .page-articles-i-am-success #content #i-am-success .cover {
    margin: 0 auto 50px;
  }
}

/* Content - Articles - Why Did You Do This To Me Page */
.page-articles-why-did-you-do-this-to-me #content #why-did-you-do-this-to-me {
  padding: 100px 0;
}

.page-articles-why-did-you-do-this-to-me #content #why-did-you-do-this-to-me h2 {
  text-align: center;
}

.page-articles-why-did-you-do-this-to-me #content #why-did-you-do-this-to-me .why-did-you-do-this-to-me {
  margin-top: 50px;
}

.page-articles-why-did-you-do-this-to-me #content #why-did-you-do-this-to-me .why-did-you-do-this-to-me .cover {
  max-width: 310px;
  box-shadow: 10px 10px 5px #ccc;
}

.page-articles-why-did-you-do-this-to-me #content #why-did-you-do-this-to-me .why-did-you-do-this-to-me .cover img {
  width: 100%;
}

.page-articles-why-did-you-do-this-to-me #content #why-did-you-do-this-to-me .why-did-you-do-this-to-me .description p a {
  color: #337ab7;
  text-decoration: none;
}

.page-articles-why-did-you-do-this-to-me #content #why-did-you-do-this-to-me .why-did-you-do-this-to-me .description p a:hover {
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  .page-articles-why-did-you-do-this-to-me #content #why-did-you-do-this-to-me .cover {
    margin: 0 auto 50px;
  }
}

/* Content - Articles - Falling In Love With Yourself Page */
.page-articles-falling-in-love-with-yourself #falling-in-love-with-yourself {
  padding: 100px 0;
  text-align: center;
}

.page-articles-falling-in-love-with-yourself #falling-in-love-with-yourself .falling-in-love-with-yourself {
  margin-top: 50px;
}

.page-articles-falling-in-love-with-yourself #falling-in-love-with-yourself .falling-in-love-with-yourself .cover {
  width: 310px;
  height: 401px;
  float: left;
  background: url("/img/articles/bg-falling-in-love-with-yourself.jpg?v=2") no-repeat;
  box-shadow: 10px 10px 5px #ccc;
}

.page-articles-falling-in-love-with-yourself #falling-in-love-with-yourself .falling-in-love-with-yourself .description {
  width: 570px;
  float: right;
  font-family: 'Georgia', Arial, sans-serif;
  font-size: 1.3em;
  text-align: left;
  line-height: 35px;
}

.page-articles-falling-in-love-with-yourself #falling-in-love-with-yourself .falling-in-love-with-yourself .add-to-cart {
  float: left;
  padding: 10px 13px;
  font-family: 'Gill Sans', Arial, sans-serif;
  font-size: 1em;
  color: #000;
  line-height: 15px;
  text-decoration: none;
  text-align: center;
  border: 1px solid #000;
  background-color: #e9c881;
}

/* Content - Articles - Women Cheat Page */
.page-articles-women-cheat #content #women-cheat {
  padding: 100px 0;
}

.page-articles-women-cheat #content #women-cheat h2 {
  text-align: center;
}

.page-articles-women-cheat #content #women-cheat .women-cheat {
  margin-top: 50px;
}

.page-articles-women-cheat #content #women-cheat .women-cheat .cover {
  max-width: 310px;
  box-shadow: 10px 10px 5px #ccc;
}

.page-articles-women-cheat #content #women-cheat .women-cheat .cover img {
  width: 100%;
}

.page-articles-women-cheat #content #women-cheat .women-cheat .description p a {
  color: #337ab7;
  text-decoration: none;
}

.page-articles-women-cheat #content #women-cheat .women-cheat .description p a:hover {
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  .page-articles-women-cheat #content #women-cheat .cover {
    margin: 0 auto 50px;
  }
}

/* Content - Articles - Consciousness Is The Catalyst For Change */
.page-articles-consciousness-is-the-catalyst-for-change #content #consciousness-is-the-catalyst-for-change {
  padding: 100px 0;
}

.page-articles-consciousness-is-the-catalyst-for-change #content #consciousness-is-the-catalyst-for-change h2 {
  text-align: center;
}

.page-articles-consciousness-is-the-catalyst-for-change #content #consciousness-is-the-catalyst-for-change .consciousness-is-the-catalyst-for-change {
  margin-top: 50px;
}

.page-articles-consciousness-is-the-catalyst-for-change #content #consciousness-is-the-catalyst-for-change .consciousness-is-the-catalyst-for-change .cover {
  max-width: 310px;
  box-shadow: 10px 10px 5px #ccc;
}

.page-articles-consciousness-is-the-catalyst-for-change #content #consciousness-is-the-catalyst-for-change .consciousness-is-the-catalyst-for-change .cover img {
  width: 100%;
}

.page-articles-consciousness-is-the-catalyst-for-change #content #consciousness-is-the-catalyst-for-change .consciousness-is-the-catalyst-for-change .description p a {
  color: #337ab7;
  text-decoration: none;
}

.page-articles-consciousness-is-the-catalyst-for-change #content #consciousness-is-the-catalyst-for-change .consciousness-is-the-catalyst-for-change .description p a:hover {
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  .page-articles-consciousness-is-the-catalyst-for-change #content #consciousness-is-the-catalyst-for-change .cover {
    margin: 0 auto 50px;
  }
}

/* Content - Books */
.page-books #content #books {
  padding: 100px 0;
}

.page-books #content #books h2 {
  text-align: center;
}

.page-books #content #books .books {
  margin-top: 50px;
}

.page-books #content #books .books .cover {
  max-width: 310px;
}

.page-books #content #books .books .cover img {
  width: 100%;
}

.page-books #content #books .books .description p a {
  color: #337ab7;
  text-decoration: none;
}

.page-books #content #books .books .description p a:hover {
  text-decoration: underline;
}

.page-books #content #books .books .description p .highlight {
  color: #73308c;
}

@media (max-width: 991.98px) {
  .page-books #content #books .cover {
    margin: 0 auto 50px;
  }
}

/* Content - Videos Page */
.page-videos #videos {
  padding: 100px 0;
  text-align: center;
}

.page-videos #videos .videos {
  margin-top: 50px;
  font-family: 'Georgia', Arial, sans-serif;
  font-size: 1.3em;
  text-align: left;
}

.page-videos #videos .videos .video {
  text-align: center;
}

.page-videos #videos .videos .add-to-cart {
  width: 150px;
  display: block;
  margin: 0 auto;
  padding: 10px 13px;
  font-family: 'Gill Sans', Arial, sans-serif;
  font-size: 1em;
  color: #000;
  line-height: 15px;
  text-decoration: none;
  text-align: center;
  border: 1px solid #000;
  background-color: #e9c881;
}

/* Content - Free Gifts */
.page-free-gifts #content #free-gifts {
  padding: 100px 0;
}

.page-free-gifts #content #free-gifts h2, .page-free-gifts #content #free-gifts h3 {
  text-align: center;
}

.page-free-gifts #content #free-gifts .tagline {
  margin: 15px 0 0;
  font-size: 20px;
  color: #000;
  text-align: center;
}

.page-free-gifts #content #free-gifts .articles {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}

.page-free-gifts #content #free-gifts .articles article {
  text-align: center;
}

.page-free-gifts #content #free-gifts .articles article .cover {
  max-width: 310px;
  margin-bottom: 25px;
  display: block;
  border: 1px solid #000;
}

.page-free-gifts #content #free-gifts .articles article .cover img {
  width: 100%;
}

.page-free-gifts #content #free-gifts .articles article .more-info {
  font-size: 1.2em;
  color: #73308c;
  text-transform: uppercase;
  text-decoration: none;
}

.page-free-gifts #content #free-gifts .process-free-gifts {
  margin-top: 100px;
}

.page-free-gifts #content #free-gifts .process-free-gifts .alert {
  border-radius: 0;
}

.page-free-gifts #content #free-gifts .process-free-gifts .alert ul {
  margin: 0;
}

.page-free-gifts #content #free-gifts .process-free-gifts .form-free-gifts {
  margin: 50px auto 0;
  max-width: 500px;
}

.page-free-gifts #content #free-gifts .process-free-gifts .form-free-gifts label {
  font-weight: 500;
}

.page-free-gifts #content #free-gifts .process-free-gifts .form-free-gifts input[type="text"],
.page-free-gifts #content #free-gifts .process-free-gifts .form-free-gifts input[type="email"],
.page-free-gifts #content #free-gifts .process-free-gifts .form-free-gifts input[type="tel"] {
  height: auto;
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 0;
}

.page-free-gifts #content #free-gifts .process-free-gifts .form-free-gifts .btn-submit {
  color: #fff;
  background-color: #C221F8;
  border-color: #ac1ddc;
  border-radius: 0;
}

.page-free-gifts #content #free-gifts .process-free-gifts .form-free-gifts a {
  color: #337ab7;
  text-decoration: none;
}

.page-free-gifts #content #free-gifts .process-free-gifts .form-free-gifts a:hover {
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .page-free-gifts #content #free-gifts .articles {
    display: block;
    max-width: 310px;
    margin: 50px auto 0;
  }
  .page-free-gifts #content #free-gifts .articles article:last-child {
    margin-top: 50px;
  }
}

/* Content - Healing Herbs Page */
.page-healing-herbs #content #healing-herbs {
  padding: 100px 0;
  text-align: center;
}

.page-healing-herbs #content #healing-herbs .healing-herbs {
  margin-top: 50px;
}

.page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs {
  padding: 0 150px 100px;
}

.page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs .carousel-inner .carousel-item .product .product-img img {
  width: 100%;
  border: 2px solid #73308c;
}

.page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs .carousel-inner .carousel-item .product .product-img .caption {
  margin-top: 5px;
  font-size: 14px;
}

.page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs .carousel-inner .carousel-item .product .product-description p {
  margin-bottom: 25px;
  text-align: left;
}

.page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs .carousel-inner .carousel-item .product .product-description p a {
  color: #337ab7;
  text-decoration: none;
}

.page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs .carousel-inner .carousel-item .product .product-description p a:hover {
  text-decoration: underline;
}

.page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs .carousel-indicators button {
  background-color: #73308c;
}

.page-healing-herbs #content #healing-herbs .healing-herbs .disclaimer {
  margin-top: 50px;
}

@media (max-width: 991.98px) {
  .page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs .carousel-inner .carousel-item .product .product-description {
    margin-top: 50px;
  }
}

@media (max-width: 767.98px) {
  .page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs {
    padding: 0 0 100px;
  }
  .page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs .carousel-control-prev,
  .page-healing-herbs #content #healing-herbs .healing-herbs #carousel-healing-herbs .carousel-control-next {
    display: none;
  }
}

/* Content - 1:1 Coaching Page */
.page-1-1-coaching #content .mindset-makeover {
  display: none;
}

.page-1-1-coaching #content #one-on-one-coaching {
  padding: 100px 0;
}

.page-1-1-coaching #content #one-on-one-coaching h2 {
  text-align: center;
}

.page-1-1-coaching #content #one-on-one-coaching .features {
  margin-top: 50px;
}

.page-1-1-coaching #content #one-on-one-coaching .features .intense-private-coaching {
  text-align: center;
}

.page-1-1-coaching #content #one-on-one-coaching .features .includes {
  margin-top: 50px;
}

.page-1-1-coaching #content #one-on-one-coaching .features .includes ul {
  list-style: none;
}

.page-1-1-coaching #content #one-on-one-coaching .features .includes ul li {
  margin-top: 10px;
}

.page-1-1-coaching #content #one-on-one-coaching .features .includes ul li span:has(span) {
  position: relative;
  left: 10px;
}

.page-1-1-coaching #content #one-on-one-coaching .features .includes ul li span:has(span) .highlight {
  font-weight: bold;
  color: red;
}

.page-1-1-coaching #content #one-on-one-coaching .features .includes ul li:before {
  content: '✓';
  font-size: 28px;
  color: #00bf00;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form {
  margin-top: 50px;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form p {
  margin-bottom: 0;
  text-align: center;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form p:first-child {
  color: #337ab7;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form p .highlight {
  font-style: normal;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form > ul {
  color: #337ab7;
  text-align: left;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching {
  max-width: 500px;
  margin: 50px auto 0;
  text-align: left;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching .alert {
  border-radius: 0;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching .alert ul {
  margin: 0;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching ul {
  margin-left: 25px;
  font-size: 16px;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching label {
  font-weight: 500;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching input[type="text"],
.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching input[type="email"],
.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching input[type="tel"] {
  height: auto;
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 0;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching .btn-submit {
  color: #fff;
  background-color: #C221F8;
  border-color: #ac1ddc;
  border-radius: 0;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching a {
  color: #337ab7;
  text-decoration: none;
}

.page-1-1-coaching #content #one-on-one-coaching .features .form .form-1-1-coaching a:hover {
  text-decoration: underline;
}

/* Content - Special Offers Page */
.page-special-offers #special-offers {
  padding: 100px 0;
  text-align: center;
}

.page-special-offers #special-offers .special-offers {
  margin-top: 50px;
  font-family: 'Georgia', Arial, sans-serif;
  font-size: 1.3em;
}

.page-special-offers #special-offers .special-offers .special-offers-articles {
  width: 685px;
  height: 322px;
  margin: 0 auto;
  background: url("/img/bg-special-offers-articles.png?v=2") no-repeat;
}

.page-special-offers #special-offers .special-offers .highlight {
  font-size: 3.5em;
  color: #ccc;
}

.page-special-offers #special-offers .special-offers .special-offers-best-seller {
  width: 310px;
  height: 401px;
  display: block;
  margin: 0 auto;
}

.page-special-offers #special-offers .special-offers .discount {
  color: #ff0000;
}

.page-special-offers #special-offers .special-offers .discount span {
  font-size: 1.5em;
}

.page-special-offers #special-offers .special-offers .line-through {
  font-size: 1.3em;
  text-decoration: line-through;
}

.page-special-offers #special-offers .special-offers .add-to-cart {
  width: 175px;
  display: block;
  margin: 0 auto;
  padding: 10px 13px;
  font-family: 'Gill Sans', Arial, sans-serif;
  font-size: 1em;
  color: #000;
  line-height: 15px;
  border: 1px solid #000;
  background-color: #e9c881;
}

/* Content - Cart Page */
.page-cart #cart {
  padding: 100px 0;
  text-align: center;
}

.page-cart #cart .cart {
  margin-top: 50px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  text-align: left;
}

/* Content - Cart Login Page */
.page-cart-login #cart-login {
  padding: 100px 0;
  text-align: center;
}

.page-cart-login #cart-login .cart-login {
  margin-top: 50px;
  font-family: 'Georgia', Arial, sans-serif;
  font-size: 1.3em;
  text-align: left;
}

.page-cart-login #cart-login .cart-login .sign-up {
  padding-left: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.page-cart-login #cart-login .cart-login .login {
  padding-right: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.page-cart-login #cart-login .cart-login label {
  font-size: 0.8em;
}

.page-cart-login #cart-login .cart-login .alert {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8em;
}

/* Content - Pay Page */
.page-pay #pay {
  padding: 100px 0;
  text-align: center;
}

.page-pay #pay .pay {
  margin-top: 50px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  text-align: left;
}

.page-pay #pay .pay .alert {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8em;
}

.page-pay #pay .pay .payment {
  margin-top: 50px;
}

.page-pay #pay .pay .payment form {
  width: 500px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8em;
}

.page-pay #pay .pay .payment .row .col-xs-6:first-child {
  padding-left: 0;
}

.page-pay #pay .pay .payment .row .col-xs-6:nth-child(2) {
  padding-right: 0;
}

/* Content - Pay Success Page */
.page-checkout-success #checkout-success {
  padding: 100px 0;
  text-align: center;
}

.page-checkout-success #checkout-success .checkout-success {
  margin-top: 50px;
  font-family: 'Georgia', Arial, sans-serif;
  font-size: 1.3em;
}

/* Content - Contact Page */
.page-contact #content #contact {
  padding: 100px 0;
  text-align: center;
}

.page-contact #content #contact .contact {
  margin-top: 50px;
}

.page-contact #content #contact .contact p:last-child {
  margin-top: 25px;
}

.page-contact #content #contact .contact p a {
  color: #337ab7;
  text-decoration: none;
}

.page-contact #content #contact .contact p a:hover {
  text-decoration: underline;
}

/* Content - Dedications Page */
.page-dedications #content #dedications {
  padding: 100px 0;
  text-align: center;
}

.page-dedications #content #dedications .dedications {
  margin-top: 50px;
}

.page-dedications #content #dedications .dedications p {
  margin-top: 50px;
}

.page-dedications #content #dedications .dedications p:first-child {
  margin-top: 0;
  font-weight: bold;
}

.page-dedications #content #dedications .dedications .highlight {
  font-weight: bold;
  color: #73308c;
}

.page-dedications #content #dedications .dedications .dedication {
  max-width: 500px;
  margin: 50px auto 0;
  padding: 15px;
  background-color: #f6f6f6;
  box-shadow: 7px 7px 0 #73308c;
}

/* Content - Privacy Policy Page */
.page-privacy #content #privacy-policy {
  padding: 100px 0;
}

.page-privacy #content #privacy-policy h2 {
  text-align: center;
}

.page-privacy #content #privacy-policy .privacy-policy {
  margin-top: 50px;
}

.page-privacy #content #privacy-policy .privacy-policy ul {
  margin: 25px 0 0 25px;
}

/* Content - Terms & Conditions Page */
.page-terms #content #terms-and-conditions {
  padding: 100px 0;
}

.page-terms #content #terms-and-conditions h2 {
  text-align: center;
}

.page-terms #content #terms-and-conditions .terms-and-conditions {
  margin-top: 50px;
}

.page-terms #content #terms-and-conditions .terms-and-conditions ul {
  margin: 25px 0 0 25px;
}

/* Footer */
#footer {
  padding: 50px 0;
  background-color: #35363a;
}

#footer .social-network ul {
  padding: 0;
}

#footer .social-network ul li {
  margin-left: 10px;
  display: inline-block;
}

#footer .social-network ul li:first-child {
  margin-left: 0;
}

#footer .social-network ul li a {
  max-width: 30px;
  display: block;
}

#footer .social-network ul li a img {
  width: 100%;
}

#footer .copyright {
  text-align: center;
  color: #898989;
}

#footer .designed_hosted_by {
  margin-bottom: 0;
  color: #898989;
  text-align: center;
}

#footer .designed_hosted_by a {
  color: #898989;
  text-decoration: none;
}

#footer .designed_hosted_by a:hover {
  text-decoration: underline;
}

#footer nav {
  text-align: right;
}

#footer nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#footer nav ul li {
  display: inline;
  margin-right: 2px;
  padding-left: 10px;
  background: url("/img/bg-bullet.png") no-repeat center left;
}

#footer nav ul li a {
  color: #c1c0c0;
  text-decoration: none;
}

#footer nav ul li a:hover {
  text-decoration: underline;
}

#footer nav ul li:first-child {
  padding-left: 0;
  background: none;
}

@media (max-width: 991.98px) {
  #footer .social-network {
    text-align: center;
  }
  #footer .copyright {
    margin-top: 25px;
  }
  #footer nav {
    margin-top: 25px;
    text-align: center;
  }
}

/* Client Area - My Account */
.page-client-area-my-account #my-account {
  padding: 100px 0;
  text-align: center;
}

.page-client-area-my-account #my-account .my-account {
  margin-top: 50px;
  font-size: 1.2em;
  text-align: left;
}

.page-client-area-my-account #my-account .my-account .my-profile, .page-client-area-my-account #my-account .my-account .edit-profile, .page-client-area-my-account #my-account .my-account .order-history, .page-client-area-my-account #my-account .my-account .view-order, .page-client-area-my-account #my-account .my-account .view-downloads, .page-client-area-my-account #my-account .my-account .invoices, .page-client-area-my-account #my-account .my-account .view-invoice {
  margin-top: 25px;
}

.page-client-area-my-account #my-account .my-account .edit-profile {
  font-size: 14px;
}

.page-client-area-my-account #my-account .my-account .edit-profile form {
  width: 460px;
}

/* Client Area - Show Invoice */
.page-client-area-show-invoice {
  padding: 75px 0 100px;
}

.page-client-area-show-invoice #billing-info {
  margin-top: 50px;
}

.page-client-area-show-invoice #billing-info .col-xs-6:first-child {
  padding-left: 0;
}

.page-client-area-show-invoice #billing-info .col-xs-6:last-child {
  padding-right: 0;
}

@media print {
  .page-client-area-show-invoice .panel .panel-heading {
    background-color: #337ab7 !important;
  }
  .page-client-area-show-invoice .panel .panel-heading h3 {
    color: #fff !important;
  }
  .page-client-area-show-invoice table thead th {
    color: #fff !important;
    background: #337ab7 !important;
    -webkit-print-color-adjust: exact;
  }
  .page-client-area-show-invoice .btn-print {
    display: none;
  }
}

/* Client Area - Login */
.page-client-area-login #client-area-login {
  padding: 100px 0;
  text-align: center;
}

.page-client-area-login #client-area-login .client-area-login {
  margin-top: 50px;
  font-family: 'Georgia', Arial, sans-serif;
  font-size: 1.3em;
}

.page-client-area-login #client-area-login .client-area-login .sign-in, .page-client-area-login #client-area-login .client-area-login .register {
  text-align: left;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.page-client-area-login #client-area-login .client-area-login .sign-in a, .page-client-area-login #client-area-login .client-area-login .register a {
  font-size: 0.8em;
}

.page-client-area-login #client-area-login .client-area-login .sign-in {
  padding-left: 0;
}

.page-client-area-login #client-area-login .client-area-login .register {
  padding-right: 0;
}

.page-client-area-login #client-area-login .client-area-login label {
  font-size: 0.8em;
}

.page-client-area-login #client-area-login .client-area-login .alert {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8em;
  text-align: left;
}

/* Client Area - Forgot Password? */
.page-client-area-forgot-password #client-area-forgot-password {
  padding: 100px 0;
  text-align: center;
}

.page-client-area-forgot-password #client-area-forgot-password .client-area-forgot-password {
  margin-top: 50px;
  font-family: 'Georgia', Arial, sans-serif;
  font-size: 1.3em;
}

.page-client-area-forgot-password #client-area-forgot-password .client-area-forgot-password .forgot-password {
  float: none;
  margin: 0 auto;
  text-align: left;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.page-client-area-forgot-password #client-area-forgot-password .client-area-forgot-password .forgot-password a {
  font-size: 0.8em;
}

.page-client-area-forgot-password #client-area-forgot-password .client-area-forgot-password label {
  font-size: 0.8em;
}

.page-client-area-forgot-password #client-area-forgot-password .client-area-forgot-password .alert {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8em;
  text-align: left;
}

/* Admin Area - General */
[class*="page-admin-area"] {
  padding: 75px 0 100px;
}

/* Admin Area - Login */
.page-admin-area-login {
  padding: 100px 0;
}

.page-admin-area-login .login {
  width: 350px;
  margin: 0 auto;
}

.page-admin-area-login .login #logo {
  width: 327px;
  height: 58px;
  display: block;
  margin: 0 auto;
  background: url("/img/logo.png") no-repeat;
}

.page-admin-area-login .login .panel {
  margin-top: 50px;
}

/* Admin Area - Dashboard */
.page-admin-area-dashboard .dashboard .statistics {
  padding: 0;
}

.page-admin-area-dashboard .dashboard .statistics .col-xs-4:first-child {
  padding-left: 0;
}

.page-admin-area-dashboard .dashboard .statistics .col-xs-4:last-child {
  padding-right: 0;
}

/* Admin Area - Show Invoice */
.page-admin-area-view-invoice .panel {
  border-radius: 0;
}

.page-admin-area-view-invoice .panel .panel-heading {
  border-radius: 0;
}

.page-admin-area-view-invoice #billing-info {
  margin-top: 50px;
}

.page-admin-area-view-invoice #billing-info .col-xs-6:first-child {
  padding-left: 0;
}

.page-admin-area-view-invoice #billing-info .col-xs-6:last-child {
  padding-right: 0;
}

@media print {
  .page-admin-area-view-invoice .panel .panel-heading {
    background-color: #337ab7 !important;
  }
  .page-admin-area-view-invoice .panel .panel-heading h3 {
    color: #fff !important;
  }
  .page-admin-area-view-invoice table thead th {
    color: #fff !important;
    background: #337ab7 !important;
    -webkit-print-color-adjust: exact;
  }
  .page-admin-area-view-invoice .btn-print {
    display: none;
  }
}

/* Admin Area - Footer */
.footer-admin .container {
  padding-top: 13px;
}
